import AccountProduct, { rate } from "../product-models/AccountProduct"
import Product from "../Product"
import Feature from "../Feature"

let obj: any = null
export default class AccountFeature extends Feature {

  public readonly descriptionFirstLine: string;
  public readonly transactionFeature: boolean;

  // = ABSTRACT OVERRIDES

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    // filterAmounts
    const filterAmounts: { [id: string]: number } = {
      "Major Banks": 0,
      "Challengers": 0,
      "Non-banks": 0,
    }

    Object.keys(this.products).forEach((productId: string) => {
      const product = this.products[productId] as AccountProduct
      const productFilterAmounts = product.getFilterAmounts()
      Object.keys(productFilterAmounts).forEach(id => {
        if (filterAmounts[id] !== undefined) {
          filterAmounts[id] += productFilterAmounts[id]
        } else {
          filterAmounts[id] = productFilterAmounts[id]
        }
      })
    })

    return filterAmounts
  }

  public getTitle(): string {
    return `Compare ${this.name} ${this.transactionFeature ? 'Bank' : 'Savings'} Accounts | Stay or Go`
  }

  public getAutoDescription(): string {
    let autoDescription = this.descriptionFirstLine || "Compare the latest " + this.name + ` ${this.transactionFeature ? 'bank' : 'savings'} account offers.`
    // let highestRate = Number.MIN_VALUE
    // Object.keys(this.products).forEach(prodId => {
    //   const txProd = this.products[prodId] as AccountProduct
    //   Object.keys(txProd.rates).forEach(rateId => {
    //     const rate = txProd.rates[rateId]
    //     if (rate.interestRate > highestRate && rate.rateCategory === "STANDARD") {
    //       highestRate = rate.interestRate
    //     }
    //   })
    // })
    // if (highestRate > 0) {
    //   autoDescription += ` Rates up to ${((highestRate) * 100).toFixed(2)}% p.a.`
    // }
    return autoDescription
  }

  // getProductList
  public getProductList(makeProductComponent: (product: Product, key: number, isLast?: boolean | undefined, offerFilter?: string) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []

    const sortedProducts: Product[] = []
    Object.values(this.products).forEach(p => {
      sortedProducts.push(p)
    })
    sortedProducts.sort((a, b) => this.compare(a, b))

    // Categorise by providers
    const productsByProviders: { [id: string]: Product[] } = {}
    sortedProducts.forEach(product => {
      const productProvider: string = product.providerName

      if (productProvider in productsByProviders) {
        productsByProviders[productProvider].push(product)
      } else {
        productsByProviders[productProvider] = [product]
      }
    })

    // Fill product list
    Object.keys(productsByProviders).forEach(providerName => {
      const products: Product[] = productsByProviders[providerName]

      // Add Provider Header to list
      if (products.length > 0) {
        productList.push(makeCategoryHeader(providerName))
      }

      // Add Product Components to list
      products.forEach((prod: any, key: number) => {
        productList.push(makeProductComponent(AccountProduct.Initialise(prod), key))
      })
    })

    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Parse(d: string): AccountFeature {
    return AccountFeature.Create(JSON.parse(d))
  }
  public static Create(d: any, field: string = "root"): AccountFeature {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.name, false, field + ".name")
    checkString(d.urlSlug, true, field + ".urlSlug")
    checkString(d.descriptionFirstLine, true, field + ".urlSlug")
    d.transactionFeature = !!d.transactionFeature
    d.nameFormatted = d.name
    d.heading = d.name
    d.descriptionFirstLine = d.descriptionFirstLine || ''
    d.products = {}
    return new AccountFeature(d)
  }
  public static Initialise(d: any): AccountFeature {
    return new AccountFeature(d)
  }
  private constructor(d: any) {
    super(d)
    this.descriptionFirstLine = d.descriptionFirstLine
    this.transactionFeature = d.transactionFeature
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
