import React, { useEffect, useRef } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Select, MenuItem, TextField } from "@mui/material"
import * as styles from "./style.module.scss"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"

const useStyles = () => ({
  inputField: {
    width: "100%",
    height: "56px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: useMediaQuery("(min-width:768px)") ? "24px" : "20px",
    color: "#202020",
    paddingLeft: "10px",
    paddingRight: "10px",
  }
})

const InputFilter = (
  props: { setDepositAmount: (depositAmount: number) => void }
) => {
  const classes = useStyles()
  const loanAmountRef = useRef<HTMLInputElement>()
  const appManager = AppManager.getInstance()

  useEffect(() => {
    window.onscroll = () => {
      loanAmountRef.current?.blur()
    }
  }, [])

  const formatLoanAmount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = Number(e.target.value.replace(/\D+/g, ''))
    if (value > 99999999) {
      value = 99999999
    }
    e.target.value = "$" + value.toLocaleString()
  }

  const updateDepositAmount = (v: string) => {
    const value = Number(v.replace(/\D+/g, ''))
    appManager.setSavingsDepositAmount(value)
    props.setDepositAmount(value)
    DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_FILTER_INPUT, { action: "savingsDeposit", value: v })
  }

  return (
    <div>
      <div className={styles.inputFilterHeader}>
        Enter scenario to see relevant rates
      </div>
      <div className={styles.inputFilterContainer}>
        <div className={styles.inputCellContainer}>
          <div className={styles.inputLabel}>I want to deposit</div>
          <div className={styles.inputFieldContainer}>
            <TextField
              defaultValue={"$" + appManager.getSavingsDepositAmount().toLocaleString()}
              variant="standard"
              fullWidth
              inputRef={loanAmountRef}
              InputProps={{
                disableUnderline: true,
                style: classes.inputField,
                // className: styles.inputField,
              }}
              onChange={(e) => formatLoanAmount(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Escape") {
                  (e.target as EventTarget & HTMLDivElement).blur()
                }
              }}
              onBlur={(e) => updateDepositAmount(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )

}

export default InputFilter