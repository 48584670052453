// extracted by mini-css-extract-plugin
export var button = "style-module--button--3b58e";
export var cardImage = "style-module--card-image--b4b8d";
export var cardImageImg = "style-module--card-image-img--2e6e6";
export var cardRow = "style-module--card-row--03d3c";
export var cardValuesHeader = "style-module--card-values-header--f085f";
export var cardValuesHeaderMobile = "style-module--card-values-header-mobile--b7723";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--ff986";
export var description = "style-module--description--606d2";
export var dropdown = "style-module--dropdown--40f67";
export var heading = "style-module--heading--9cb59";
export var innerProductComponent = "style-module--inner-product-component--2e5d2";
export var listHeader = "style-module--list-header--a5dab";
export var listHeaderMobile = "style-module--list-header-mobile--3966a";
export var productComponent = "style-module--product-component--297da";
export var rightContainer = "style-module--right-container--e952b";